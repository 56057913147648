import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../images/logoSimple.png";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useUserInfo } from "../../utils/user";
import { useAuth } from "../../utils/auth";

const Header = () => {
  const { user, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isCoach, stripeCustomerId } = useUserInfo();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoPath = isCoach ? "/coach" : user.accessToken ? "/workouts" : "/";
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="Logo" width="30" />
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ ml: 1, "text-decoration": "none" }}
            component={Link}
            to={logoPath}
          >
            Trainer App
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {!user.accessToken ? (
            <Typography
              sx={{ color: "white", textDecoration: "none" }}
              component={Link}
              to="/login"
            >
              Login
            </Typography>
          ) : (
            <>
              <IconButton id="basic-button" onClick={handleClick}>
                <MenuIcon fontSize="large" sx={{ color: "white" }} />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem component={Link} to="/workouts" onClick={handleClose}>
                  Workouts
                </MenuItem>
                {/* <MenuItem component={Link} to="/messages" onClick={handleClose}>
                  Messages
                </MenuItem> */}
                {stripeCustomerId ? (
                  <MenuItem
                    component={Link}
                    to="/subscription"
                    onClick={handleClose}
                  >
                    Subscription
                  </MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/payment"
                    onClick={handleClose}
                  >
                    Payment
                  </MenuItem>
                )}
                {isCoach && (
                  <MenuItem component={Link} to="/coach" onClick={handleClose}>
                    Coach Portal
                  </MenuItem>
                )}
                <MenuItem>
                  {user.accessToken && (
                    <Typography onClick={signOut}>Logout</Typography>
                  )}
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
