import React, { useState } from "react";
import { Grid } from "@mui/material";
import Clients from "./Clients";
import ClientWorkouts from "./ClientWorkouts";

const Programming = () => {
  const [client, setClient] = useState({});
  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={4}>
        <Clients setClient={setClient} activeClient={client} />
      </Grid>
      <Grid item lg={8} md={8}>
        {client?.id && <ClientWorkouts client={client} />}
      </Grid>
    </Grid>
  );
};

export default Programming;
