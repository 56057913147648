import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertTitle, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../utils/api';
import Loading from './Layout/Loading';

const ClientIntakeForm = () => {
    const navigate = useNavigate();
    const { submitClientIntake, getUser } = useApi();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { encodedCoachId } = useParams();
    const [coach, setCoach] = useState(null);

    useEffect(() => {
        const get = async () => {
            const coachId = atob(encodedCoachId);
            if(coachId){
                const userResponse = await getUser(coachId);
                setCoach(userResponse);
            } else {
                navigate('/');
            }
        }
        get();
    }, [encodedCoachId]);

    const required = (v) => {
        return !v ? 'This field is required' : undefined
    }
    const onSubmit = async (v) => {
        setLoading(true);
        await submitClientIntake(v);
        setLoading(false);
        setSuccess(true);
    }

    if(!coach){
        return <Loading />
    }

    if(success){
        return (
            <Alert severity="success">
                <AlertTitle>Thanks for reaching out!</AlertTitle>
                I'll be in touch with you shortly to schedule a video consultation. Please be on the look out for my email.
            </Alert>
        )
    }
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <Typography variant="h6">Client Intake Form</Typography>
                <Field
                    name="dateSubmitted"
                    render={({input}) => (
                        <input type='hidden' value={input.value} />
                    )} />
                <Field
                    name="coachId"
                    render={({input}) => (
                        <input type='hidden' value={input.value} />
                    )} />
                <Field
                    name="fullname"
                    validate={required}
                    render={({input, meta}) => (
                        <TextField label="Full Name" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth />
                    )} />
                <Field
                    name="email"
                    validate={required}
                    render={({input, meta}) => (
                        <TextField label="Email" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth />
                    )} />
                  <Field
                    name="age"
                    validate={required}
                    render={({input, meta}) => (
                        <TextField label="Age" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth />
                    )} />
                   <Typography sx={{mt: 2}} fontSize="small">Do you have any injuries, current or past, that could affect you during exercise?</Typography>
                    <Field
                        name="injuries"
                        validate={required}
                        render={({input, meta}) => (
                            <TextField label="Injury history" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth multiline/>
                        )} />
                        <Typography sx={{mt: 2}} fontSize="small">Do you have any medical conditions that could affect you during exercise? (Asthma, Diabetes, High Blood Pressure, etc).</Typography>
                    <Field
                        name="medical"
                        validate={required}
                        render={({input, meta}) => (
                            <TextField label="Medical History" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth multiline />
                    )} />
                   <Typography sx={{mt: 2}} fontSize="small">What are some of your goals?</Typography>
                    <Field
                        name="goals"
                        validate={required}
                        render={({input, meta}) => (
                            <TextField label="Goals" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth multiline />
                    )} />
                    <Typography sx={{mt: 2}} fontSize="small">What does your current exercise routine look like?</Typography>
                    <Field
                        name="currentExercise"
                        validate={required}
                        render={({input, meta}) => (
                            <TextField label="Current Exercise" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth multiline />
                    )} />
                    <Typography sx={{mt: 2}} fontSize="small">What are your current 1 rep maxes in the squat, bench and deadlift?</Typography>
                    <Field
                        name="current1rm"
                        validate={required}
                        render={({input, meta}) => (
                            <TextField label="Current 1 rep max" {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} sx={{mt: 2}} size="small" fullWidth multiline />
                    )} />
                    <Typography sx={{mt: 2}} fontSize="small">Anything else I should know?</Typography>
                    <Field
                        name="extra"
                        validate={required}
                        render={({input}) => (
                            <TextField label="Anything else?" {...input} sx={{mt: 2}} size="small" fullWidth multiline />
                    )} />
                    <Field name="date" value={moment().unix()} render={({input}) => (
                        <input type='hidden' {...input} />
                    )}/>
                    <LoadingButton variant="contained" fullWidth sx={{mt: 4}} type="submit" loading={loading}>Submit</LoadingButton>
            </form>
        )}
        />
    )
}

export default ClientIntakeForm;