import { Card, CardContent, Typography} from '@mui/material';

const NoWorkoutsCard = () => (
    <Card elevation={2} >
        <CardContent>
            <Typography variant="h6" component="div">
                No workouts scheduled this week
            </Typography>
        </CardContent>
    </Card>
)

export default NoWorkoutsCard;