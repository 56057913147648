import { Alert, Card, Paper, Typography } from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { useApi } from '../../utils/api';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        'color': '#32325d',
        'fontFamily': 'Roboto Mono',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

const CheckoutForm = ({ onSuccess, price, email, createOrUpdateUser }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { createCustomer, createSubscription } = useApi();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setLoading(true);

        const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                email: email,
            },
        });

        if (result.error) {
           setError(result.error.message);
           setLoading(false);
        } else {
            const customerResponse = await createCustomer({'payment_method': result.paymentMethod.id, 'email': email});
            const { stripeCustomerId } = customerResponse;
        
            if(stripeCustomerId){
                const subscriptionResponse = await createSubscription(stripeCustomerId, price);
                const { client_secret, status } = subscriptionResponse;
                await createOrUpdateUser( { stripeCustomerId } );
                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function(result) {
                    if (result.error) {
                            setError(`There was an issue! ${result.error}`);
                            setLoading(false);
                        } else {
                            onSuccess();
                        }
                    });
                } else {
                    onSuccess();
                } 
            }
        }
    };
    

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%'}}>
            <Card sm={12} lg={12} sx={{ p:2, width: '100%'}}>
                <Typography variant="h6" sx={{mb: 3}}>Payment Information</Typography>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Card>
            {error && <Alert severity="error" sx={{ mt: 3}}>{error}</Alert>}
            <LoadingButton variant="contained" fullWidth sx={{ mt: 3 }} type="submit" loading={loading}>Submit Payment</LoadingButton>
        </form>
    )
}

export default CheckoutForm;