import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tab, Tabs, Grid } from '@mui/material';
import { CoachContextProvider } from '../../utils/coach';

const Coach = () => {
    const location = useLocation();
    let pathname = location.pathname;
    if(location.pathname.indexOf('/coach/clients') > -1){
        pathname = '/coach/clients';
    } else if(location.pathname.indexOf('/coach/client-activity') > -1 || location.pathname === '/coach'){
        pathname = '/coach/client-activity';
    }

    return (
        <CoachContextProvider>
            <Tabs value={pathname} sx={{mb: 3, mt: 2}}>
                <Tab label="Activity"  component={Link} to="/coach/client-activity" value="/coach/client-activity" />
                <Tab label="Clients" component={Link} to="/coach/clients" value="/coach/clients"/>
                <Tab label="Client Intakes" component={Link} to="/coach/client-intakes" value="/coach/client-intakes"/>
            </Tabs>
            <Outlet />
        </CoachContextProvider>
    )
}

export default Coach;