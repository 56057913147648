import React from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  TextField,
  Typography,
  Button,
  Slide,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "@mui/lab/DatePicker";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import moment from "moment";
import { useApi } from "../utils/api";
import AddExercise from "./CoachPortal/AddExercise";

const AddWorkoutDetails = ({ handleClose, userId, workout = {} }) => {
  const { addWorkoutForUser, saveWorkoutDetails } = useApi();

  const onSave = (v) => {
    const payload = {
      ...v,
      exercises: v.exercises.map((e) => ({
        ...e,
        sets: e.sets.map((s) => ({
          reps: s.reps ? parseInt(s.reps) : null,
          weight: s.weight ? parseFloat(s.weight) : null,
          rpe: s.rpe ? parseInt(s.rpe) : null,
          video: s.video,
        })),
      })),
      date: v.date.unix(),
    };
    if (workout.id) {
      saveWorkoutDetails(workout.id, payload).then(() => handleClose());
    } else {
      addWorkoutForUser(userId, payload).then((r) => {
        handleClose();
      });
    }
  };
  return (
    <Dialog fullScreen open onClose={handleClose}>
      <Form
        onSubmit={onSave}
        subscription={{ initialValues: true }}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          ...workout,
          date: moment.unix(workout.date),
        }}
        render={({ handleSubmit }) => (
          <>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {workout.id ? "Edit" : "Add"} a Workout
                </Typography>
                <Button autoFocus color="inherit" onClick={handleSubmit}>
                  save
                </Button>
              </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>
              <Box>
                <Field
                  name="name"
                  subscription={{ value: true }}
                  render={({ input }) => (
                    <TextField
                      value={input.value}
                      onChange={input.onChange}
                      label="Name"
                      size="small"
                      sx={{ mt: 2 }}
                    />
                  )}
                />
              </Box>

              <Field
                name="description"
                subscription={{ value: true }}
                render={({ input }) => (
                  <TextField
                    value={input.value}
                    onChange={input.onChange}
                    label="Description"
                    size="small"
                    multiline
                    sx={{ mt: 2 }}
                    fullWidth
                  />
                )}
              />
              <Field
                name="date"
                subscription={{ value: true }}
                render={({ input }) => (
                  <DatePicker
                    value={input.value}
                    label="Date"
                    onChange={input.onChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ mt: 2 }}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                )}
              />
              <FieldArray
                name="exercises"
                subscription={{ value: true }}
                render={({ fields }) => (
                  <>
                    {fields.map((name, i) => (
                      <AddExercise
                        key={name}
                        index={i}
                        removeExercise={() => fields.remove(i)}
                      />
                    ))}
                    <Button
                      sx={{ mt: 1, mb: 1 }}
                      onClick={() => fields.push({ sets: [{}] })}
                    >
                      {" "}
                      Add Exercise{" "}
                    </Button>
                  </>
                )}
              />
            </Box>
          </>
        )}
      />
    </Dialog>
  );
};

export default AddWorkoutDetails;
