import logo from "../../images/logoSimple.png";
import { TextField, Grid, Typography, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useApi } from "../../utils/api";
import { useAuth } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import Loading from "../Layout/Loading";
import LoadingButton from "@mui/lab/LoadingButton";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const { checkIfMigrated, migrate } = useApi();
  const { login, signUp, user, setUser, sendResetPassword, error } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.accessToken) navigate("/");
  }, [user.accessToken]);

  useEffect(() => {
    setLoading(false);
  }, [error]);

  const signIn = async () => {
    setLoading(true);
    const user = await login(email, password);
    if (user) {
      setUser(user);
      navigate("/workouts");
    }
  };

  const sendForgotPasswordEmail = async () => {
    setLoading(true);
    await sendResetPassword(email);
    setSuccess("Reset password email sent. Please check your email.");
    setLoading(false);
  };

  if (user.accessToken) {
    return <Loading />;
  }
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid
        item
        display="flex"
        flexDirection="column"
        alignItems="center"
        lg={4}
      >
        <img src={logo} height="80" width="80" />
        {forgotPassword ? (
          <>
            <Typography sx={{ mb: 3 }} variant="h6">
              Forgot Password
            </Typography>
            {success && (
              <Alert severity="success" fullWidth sx={{ mb: 3, width: "100%" }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert severity="error" fullWidth sx={{ mb: 3, width: "100%" }}>
                {error}
              </Alert>
            )}
            <TextField
              size="small"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              sx={{ mb: 3 }}
            />
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={sendForgotPasswordEmail}
              loading={loading}
            >
              Send Reset Password Email
            </LoadingButton>
            <Typography
              variant="caption"
              sx={{ mt: 5, cursor: "pointer" }}
              onClick={() => setForgotPassword(false)}
            >
              Cancel
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ mb: 3 }} variant="h6">
              Sign In
            </Typography>
            {success && (
              <Alert severity="success" fullWidth sx={{ mb: 3, width: "100%" }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert severity="error" fullWidth sx={{ mb: 3, width: "100%" }}>
                {error}
              </Alert>
            )}
            <TextField
              size="small"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              sx={{ mb: 3 }}
            />
            <TextField
              size="small"
              fullWidth
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              sx={{ mb: 3 }}
            />
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={signIn}
              loading={loading}
            >
              Sign In
            </LoadingButton>
            <Typography
              variant="caption"
              sx={{ mt: 5, cursor: "pointer" }}
              onClick={() => setForgotPassword(true)}
            >
              Forgot password?
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Login;
