import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ClientActivity from "./ClientActivity";
import ReviewClientActivity from "./ReviewClientActivity";
import { useApi } from "../../utils/api";

const ReviewClients = () => {
  const [getReviewedActivity, setGetReviewedActivity] =
    useState("not-reviewed");
  const [allActivity, setAllActivity] = useState([]);
  const [reviewingActivity, setReviewingActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getActivity } = useApi();

  useEffect(() => {
    loadActivity();
  }, [getReviewedActivity]);

  const onClick = (activity) => {
    setReviewingActivity(activity);
  };

  const loadActivity = async () => {
    setLoading(true);
    const activityData = await getActivity(
      getReviewedActivity === "reviewed" ? 1 : 0
    );
    setAllActivity(activityData);
    setLoading(false);
    setReviewingActivity(activityData[0]);
  };

  return (
    <Grid container spacing={4}>
      <Grid
        lg={12}
        md={12}
        sm={12}
        item
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Client Activity</Typography>
        <ToggleButtonGroup
          color="primary"
          value={getReviewedActivity}
          exclusive
          onChange={() =>
            setGetReviewedActivity(
              getReviewedActivity === "reviewed" ? "not-reviewed" : "reviewed"
            )
          }
        >
          <ToggleButton value="not-reviewed">Not reviewed</ToggleButton>
          <ToggleButton value="reviewed">Reviewed</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {reviewingActivity && (
        <>
          <Grid item lg={4}>
            <ClientActivity
              allActivity={allActivity}
              selectedActivity={reviewingActivity}
              onClick={onClick}
            />
          </Grid>
          <Grid item lg={8}>
            <ReviewClientActivity
              activityLoading={loading}
              reviewingActivity={reviewingActivity}
              onReviewed={loadActivity}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReviewClients;
