import { List, ListItem, Paper, Typography, Button, Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
    return (
         <>
          <Typography variant="h6" sx={{ mb: 2}}> <b>Pricing</b> </Typography>
          <Paper sx={{ p: 2, mt: 2}}>
               <Typography sx={{ mb: 2}}><b>Remote Personal Training</b></Typography>
               <Typography>$100 a month</Typography>
               <Typography variant="caption">charged via credit card automatically</Typography>
               <Typography sx={{ mt: 2}}><b>Includes</b></Typography>
               <List>
                    <ListItem>
                         <Typography>Weekly custom programming</Typography>
                    </ListItem>
                    <ListItem>
                         <Typography>Form feedback via video</Typography>
                    </ListItem>
                    <ListItem>
                         <Typography>Exercise form how-to videos</Typography>
                    </ListItem>
                    <ListItem>
                         <Typography>Monthly video call check-in</Typography>
                    </ListItem>
                    <ListItem>
                         <Button fullWidth variant="contained" sx={{ mt: 2, 'font-family': 'Roboto mono'}} component={Link} to="/client-intake">Reach out to find out more</Button>
                    </ListItem>
               </List>
          </Paper>
         </>
    
    )
}

export default Pricing;