import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { Card, Typography, TextField, Button, Box } from "@mui/material";
import Loading from "../Layout/Loading";
import { useUserInfo } from "../../utils/user";
import { getMessages, subscribe, sendMessage } from "./queries";
import { ReviewExercise } from "../CoachPortal/ReviewClientActivity";

const Conversation = ({ conversationId, style = {} }) => {
  const scrollRef = useRef(null);
  const [message, setMessage] = useState("");
  const { name } = useUserInfo();

  const {
    subscribeToMore,
    fetchMore,
    loading,
    data: { messagesByDate: messages } = { items: [] },
  } = useQuery(getMessages, {
    variables: {
      conversationId: conversationId,
      nextToken: message?.nextToken,
    },
  });
  const [addMessage] = useMutation(sendMessage);
  let resolving = false;

  useEffect(() => {
    subscribeToMore({
      document: subscribe,
      variables: { conversationId: conversationId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        setTimeout(() => {
          scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
        }, 500);
        const newMessage = subscriptionData.data.onCreateMessage;
        return Object.assign({}, prev, {
          messagesByDate: {
            ...prev.messagesByDate,
            items: [newMessage, ...prev.messagesByDate.items],
          },
        });
      },
    });
  }, []);

  const handleObserver = () => {
    resolving = true;
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          messagesByDate: {
            ...fetchMoreResult.messagesByDate,
            items: [
              ...prev.messagesByDate.items,
              ...fetchMoreResult.messagesByDate.items,
            ],
          },
        });
      },
      variables: {
        conversationId: conversationId,
        nextToken: messages?.nextToken,
      },
    });
  };

  const handleInfiniteLoad = (e) => {
    if (
      scrollRef.current.scrollTop <= 1000 &&
      messages?.nextToken &&
      !resolving
    ) {
      handleObserver();
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
    }
  }, [loading, conversationId]);

  const onSend = async () => {
    const { data, error } = await addMessage({
      variables: {
        input: {
          conversationMessagesId: conversationId,
          author: name,
          body: message,
        },
      },
    });
    setMessage("");
    setTimeout(() => {
      scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
    }, 500);
  };

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <Box onScroll={handleInfiniteLoad} ref={scrollRef} sx={style}>
          {messages?.items
            .map((message) => (
              <Card
                sx={{
                  mt: 2,
                  pl: 1,
                  pr: 1,
                  backgroundColor: message.author === name ? "#fff" : "#ddd",
                }}
                elevation={3}
                key={message.id}
              >
                {message.attachment.items.length > 0 && (
                  <ReviewExercise
                    exercise={JSON.parse(message.attachment.items[0].body)}
                  />
                )}
                {message.author === name ? (
                  <>
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      flexDirection="column"
                    >
                      <Typography sx={{ mt: 2, mb: 2 }}>
                        {message.body}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1 }}>
                        {moment(message.createdAt).isSame(new Date(), "day")
                          ? moment(message.createdAt).format("h:mmA")
                          : moment(message.createdAt).format("h:mA M/D/YYYY")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography sx={{ mt: 2, mb: 2 }}>
                        {message.body}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1 }}>
                        {moment(message.createdAt).isSame(new Date(), "day")
                          ? moment(message.createdAt).format("h:mmA")
                          : moment(message.createdAt).format("h:mA M/D/YYYY")}
                      </Typography>
                    </Box>
                  </>
                )}
              </Card>
            ))
            .reverse()}
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          <TextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Box sx={{ mt: 2 }} display="flex" justifyContent="flex-end">
            <Button onClick={onSend}>send</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Conversation;
