import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import Conversation from "./Conversation";
import Conversations from "./Conversations";

const MessageCenter = () => {
  const [conversationId, setConversationId] = useState(null);
  return (
    <Grid container spacing={4} sx={{ mt: 2 }}>
      <Grid item lg={4} md={4}>
        <Conversations
          onClick={setConversationId}
          selectedConverationId={conversationId}
        />
      </Grid>
      <Grid item lg={8} md={8}>
        {conversationId && (
          <Conversation
            key={conversationId}
            conversationId={conversationId}
            style={{ height: "70vh", overflowY: "scroll", p: 1 }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MessageCenter;
