import React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { getConversation } from "../../graphql/queries";
import { Card, Typography } from "@mui/material";
import Loading from "../Layout/Loading";
import { useUserInfo } from "../../utils/user";
import moment from "moment";
import { Box } from "@mui/system";
import { getMostRecentMessage } from "./queries";

const ConversationCard = ({ conversation, onClick, selected }) => {
  const { id: currentUserId } = useUserInfo();
  const { loading, data: { messagesByDate: messages } = {} } = useQuery(
    getMostRecentMessage,
    {
      variables: { conversationId: conversation.id },
    }
  );

  return (
    <Card
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: selected ? "red" : "white",
        color: selected ? "white" : "inherit",
      }}
      elevate={2}
      onClick={() => onClick(conversation.id)}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <ConversationInfo
            conversation={conversation}
            currentUserId={currentUserId}
            message={messages.items[0]}
          />
        </>
      )}
    </Card>
  );
};

const ConversationInfo = ({ conversation, currentUserId, message }) => {
  const body = (message && message.body) || "No messages";

  const time =
    message &&
    message.updatedAt &&
    (moment(message.updatedAt).isSame(new Date(), "day")
      ? moment(message.updatedAt).format("h:mmA")
      : moment(message.updatedAt).format("h:mmA M/D/YYYY"));

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography>
          {
            conversation.users.items.filter(
              (user) => user.conversationUserID !== currentUserId
            )[0].conversationUser.name
          }
        </Typography>
      </Box>
      <Typography sx={{ mb: 1 }}>{body}</Typography>
      {time && (
        <Typography variant="caption" sx={{ mb: 1 }}>
          {time}
        </Typography>
      )}
    </>
  );
};

export default ConversationCard;
