import React from "react";
import { useParams } from "react-router-dom";
import Conversation from "./Conversation";

const ClientConversation = () => {
  const { conversationId } = useParams();

  return (
    <Conversation
      conversationId={conversationId}
      style={{ height: "75vh", overflowY: "scroll", p: 1 }}
    />
  );
};

export default ClientConversation;
