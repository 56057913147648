import { Grid, Typography, Paper, Button, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ViewClientIntake from './ViewClientIntake';
import { useApi } from '../../utils/api';
import Loading from '../Layout/Loading';

const ClientIntakes = () => {
    const { getClientIntakes } = useApi();
    const [clientIntakes, setClientIntakes ] = useState([]);
    const [selectedClientIntake, setSelectedClientIntake] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSendIntake, setOpenSendIntake] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const data = await getClientIntakes();
            setClientIntakes(data);
            setLoading(false);
        }
        fetch();
    }, []);

    if(loading){
        return <Loading />
    }

    return (
        <Box sx={{p:2}}>
            <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6">Client Intakes</Typography>
                <Button onClick={() => setOpenSendIntake(true)}>Send Intake</Button>
            </Grid>
            {clientIntakes.map((clientIntake) => (
                <Paper sx={{mt: 2, p: 2}} key={clientIntake.id}>
                    <Grid
                        sx={{mb: 1}}
                        display="flex"
                        justifyContent="space-between">
                            <Typography><b>{clientIntake.fullname}</b></Typography>
                            <Typography><b>{moment.unix(clientIntake.date).format('M/D/YYYY')}</b></Typography>
                        </Grid>
                    
                    <Typography sx={{mb: 2}}>{clientIntake.email}</Typography>
                    <Button onClick={() => setSelectedClientIntake(clientIntake)}>View Client Intake</Button>
                </Paper>
            ))}
            {selectedClientIntake && (
                <ViewClientIntake
                    clientIntake={selectedClientIntake}
                    handleClose={() => setSelectedClientIntake(null)}
                />
            )}
            {openSendIntake && (
                <SendIntake />
            )}
        </Box>
    )
}

const SendIntake = () => {
    const [email, setEmail] = useState();
    const sendIntake = async () => {

    }

    return (
        <Dialog open fullWidth>
            <DialogTitle>Send Intake</DialogTitle>
            <DialogContent>
                <TextField fullWidth value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
            </DialogContent>
            <DialogActions>
                <Button variant='contained'>Send</Button>
                <Button>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientIntakes;