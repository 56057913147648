import gql from 'graphql-tag';

export const getConversation = gql`query getUsersConversations($userId: ID!) {
  getConversationUser(id: $userId) {
    conversations {
      items {
        conversation {
          id
        }
      }
    }
  }
}
`

export const addAttachment = gql`mutation AddAttachment($input: CreateAttachmentInput!) {
  createAttachment(input: $input) {
    id
  }
}`