import { Grid, CircularProgress} from "@mui/material";
import React from "react";

const Loading = ({ style }) => (
    <Grid
        display="flex"
        justifyContent="center"
        sx={style}
    > 
        <CircularProgress />
    </Grid>
)

export default Loading;