import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import { Container } from '@mui/material';

import Header from './components/Layout/Header';
import Home from './components/Home';
import Coach from './components/CoachPortal/Coach';
import About from './components/About';
import Pricing from './components/Pricing';

import ClientIntakeForm from './components/ClientIntakeForm';
import Payment from './components/Payment/Payment';
import ProtectedRoute from './components/ProtectedRoute';
import Subscription from './components/ClientPortal/Subscription';
import ClientIntakes from './components/CoachPortal/ClientIntakes';
import LoginCallback from './components/LoginCallback';
import MyWorkouts from './components/ClientPortal/MyWorkouts';
import Coaching from './components/Coaching';
import LogWorkout from './components/ClientPortal/LogWorkout';
import ConversationsContainer from './components/Messaging/ConversationsContainer';
import Conversation from './components/Messaging/Conversation';
import Login from './components/Login/Login';
import ReviewClients from './components/CoachPortal/ReviewClients';
import Programming from './components/CoachPortal/Programming';
import MessageCenter from './components/Messaging/MessageCenter';
import ClientConversation from './components/Messaging/ClientConversation';

const App = () => {
  return (
    <Router>
      <Header />
        <Container 
          maxWidth="lg" 
          display="flex" 
          justifyContent="center" 
          spacing={2}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/pricing" element={<Pricing />}/>
            <Route path="/coaching" element={<Coaching />}/>
            <Route path="/client-intake/:encodedCoachId" element={<ClientIntakeForm />}/>
            <Route path="/login" element={<Login />} />
            <Route path='/workouts' element={<ProtectedRoute />}>
                <Route path='/workouts' element={<MyWorkouts />} />
                <Route path='/workouts/:workoutId'  element={<LogWorkout />} />
            </Route>
            <Route path='/coach' element={<ProtectedRoute />}>
              <Route path="/coach" element={<Coach />}>
                <Route index element={<ReviewClients />} />
                <Route path="clients" element={<Programming />} />
                <Route path="client-intakes" element={<ClientIntakes />} />
                <Route path="client-activity" element={<ReviewClients />} />
              </Route>
            </Route>
            <Route path='/payment' element={<ProtectedRoute />}>
              <Route path="/payment" element={<Payment/>} />
            </Route>
            <Route path='/subscription' element={<ProtectedRoute />}>
              <Route path="/subscription" element={<Subscription />} />
            </Route>
            <Route path='/messages' element={<ProtectedRoute />}>
              <Route path="/messages" element={<ConversationsContainer />} >
                <Route index element={<MessageCenter />} />
                <Route path="/messages/:conversationId" element={< ClientConversation/>} />
              </Route>
            </Route>
            <Route path='/callback' element={<LoginCallback />} />
            <Route path='*' element={<Navigate to=""/>} />
          </Routes>
        </Container>
    </Router>

  );
}

export default App;
