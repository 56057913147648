import React, { useEffect, useState } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Grid,
  DialogContent,
  DialogActions,
  ToggleButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "../../utils/api";
import Exercise from "./Exercise";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../Layout/Loading";
import Snackbar from "@mui/material/Snackbar";

const LogWorkout = () => {
  const { saveWorkoutDetails, getWorkout } = useApi();
  const [showConfirm, setShowConfirm] = useState(false);
  const [workout, setWorkout] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const get = async () => {
      const response = await getWorkout(params.workoutId);
      setWorkout(response);
    };

    get();
  }, [params?.workoutId]);

  const onSave = async (v) => {
    const values = {
      reviewed: workout.reviewed ? true : false,
      completed: v.completed,
      exercises: v.exercises.map((exercise) => ({
        ...exercise,
        sets: exercise.sets.map((set) => {
          const formattedSet = {
            ...set,
          };
          if (set.weight) {
            formattedSet.weight = parseFloat(set.weight);
          }
          return formattedSet;
        }),
      })),
    };
    const updatedWorkout = await saveWorkoutDetails(workout.id, values);
    setWorkout({ ...workout, ...updatedWorkout });
    setOpenSnackBar(true);
  };

  const handleClose = () => {
    navigate(location.state.prevPath);
  };

  if (!workout) {
    return <Loading style={{ mt: 4 }} />;
  }

  return (
    <Dialog fullScreen open onClose={handleClose}>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message="Workout Saved"
      />
      <Form
        onSubmit={onSave}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          exercises: workout.exercises,
          completed: workout.completed,
        }}
        render={({ handleSubmit, pristine }) => (
          <>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>

                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={pristine ? handleClose : () => setShowConfirm(true)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {workout.name}
                </Typography>
                <Button variant="outline" onClick={handleSubmit}>
                  save
                </Button>
              </Toolbar>
            </AppBar>
            <Grid display="flex" justifyContent="center">
              <Grid lg={6} sm={10}>
                {workout.exercises ? (
                  workout.exercises.map((exercise, i) => (
                    <Exercise
                      exercise={exercise}
                      index={i}
                      currentWorkoutDate={workout.date}
                    />
                  ))
                ) : (
                  <CircularProgress />
                )}
                <Grid sx={{ m: 2 }}>
                  <Field
                    name="completed"
                    render={({ input }) => (
                      <ToggleButton
                        size="small"
                        value="check"
                        fullWidth
                        sx={{
                          backgroundColor: "red !important",
                        }}
                        selected={input.value}
                        onChange={() => {
                          const newValue = !input.value;
                          input.onChange(newValue);
                          if (newValue) {
                            handleSubmit();
                          }
                        }}
                      >
                        <Typography sx={{ color: "white" }}>
                          {input.value ? "Completed" : "Mark Complete"}
                        </Typography>
                      </ToggleButton>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Dialog open={showConfirm}>
              <DialogContent>
                <Typography>
                  You have unsaved changes. Do you want to save them?
                </Typography>
                <DialogActions sx={{ mt: 2 }}>
                  <Button variant="contained" onClick={() => {
                    handleSubmit()
                    handleClose()
                  }}>
                    Save
                  </Button>
                  <Button onClick={() => setShowConfirm(false)}>Close</Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </>
        )}
      />
    </Dialog>
  );
};

export default LogWorkout;
