import {
  Card,
  Grid,
  Typography,
  Box,
  Chip,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import React from "react";
import moment from "moment";
import PlayArrowIcon from "@mui/icons-material/VideoCameraBackSharp";

const ClientActivity = ({ allActivity, onClick, selectedActivity = {} }) => {
  return (
    <Grid sx={{ position: "relative" }}>
      {allActivity.length ? (
        <>
          {allActivity.map((activity) => (
            <Card
              sx={{
                p: 2,
                width: "100%",
                mt: 2,
                textDecoration: "none",
                cursor: "point",
                backgroundColor:
                  selectedActivity.id === activity.id ? "red" : "white",
                color:
                  selectedActivity.id === activity.id ? "white" : "inherit",
              }}
              onClick={() => onClick(activity)}
              elevation={3}
            >
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h6">{activity.user.name}</Typography>
                  <Typography>
                    <b>{activity.name}</b>
                  </Typography>
                  <Typography>
                    {moment.unix(activity.date).format("MMMM Do YYYY")}
                  </Typography>
                </Box>
                {activity.reviewed ? (
                  <Chip label="Reviewed" color="info" />
                ) : (
                  <Chip label="Needs Review" color="primary" />
                )}
              </Box>
              <Box display="flex" sx={{ mt: 2 }}>
                <AvatarGroup max={6}>
                  {activity.exercises
                    .filter((e) => !!e.videos?.length)
                    ?.map((e) => e.videos)
                    .flat()
                    .map((v) => (
                      <Avatar sx={{ width: 40, height: 40 }}>
                        <video src={v} width="50" />
                        <PlayArrowIcon sx={{ position: "absolute" }} />
                      </Avatar>
                    ))}
                </AvatarGroup>
              </Box>
            </Card>
          ))}
        </>
      ) : (
        <Card sx={{ p: 2, mt: 2, width: "100%" }} elevation={2}>
          <Typography variant="h6">There's no new activity.</Typography>
        </Card>
      )}
    </Grid>
  );
};

export default ClientActivity;
