import React, { useState, useEffect } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../utils/api";
import { useCoach } from "../../utils/coach";
import AddWorkoutDetails from "../AddWorkoutDetails";
import WorkoutCard from "../Workouts/WorkoutCard";
import WeekSwitcher from "../Workouts/WeekSwitcher";
import NoWorkoutsCard from "../Workouts/NoWorkoutsCard";
import Loading from "../Layout/Loading";

const ClientWorkouts = ({ client }) => {
  const { getWorkoutsForUser, addBatchWorkouts, deleteWorkout } = useApi();
  const [workouts, setWorkouts] = useState([]);
  const [editingWorkout, setEditingWorkout] = useState(null);
  const [copyLoading, setCopyLoading] = useState(false);
  const [openAddWorkout, setOpenAddWorkout] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().weekday(0).startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().weekday(6).endOf("day"));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const setSelectedWorkout = (w) => {
    navigate(`/workouts/${w.id}`, { state: { prevPath: location.pathname } });
  };

  useEffect(() => {
    const get = async () => {
      const workoutsResponse = await getWorkoutsForUser(
        client.id,
        startDate.unix(),
        endDate.unix()
      );
      setWorkouts(workoutsResponse.sort((w1, w2) => w1.date - w2.date));
      setLoading(false);
    };
    get();
  }, [
    startDate.toString(),
    endDate.toString(),
    client.id,
    openAddWorkout,
    editingWorkout,
  ]);

  const copyWeek = async () => {
    setCopyLoading(true);
    const copiedWorkouts = await workouts.map((workout) => {
      return {
        ...workout,
        exercises: workout.exercises.map((e, i) => ({
          name: e.name,
          description: e.description,
          sets: e.sets.map((s) => {
            const set = {
              reps: s.reps,
              type: s.type ? s.type : i === 0 ? "WEIGHT" : "RPE",
            };
            if (s.type === "WEIGHT" || i === 0) {
              set.weight = s.weight;
            } else {
              set.rpe = s.rpe;
            }
            return set;
          }),
        })),
        date: moment.unix(workout.date).add(1, "week").unix(),
      };
    });

    await addBatchWorkouts(copiedWorkouts);
    setCopyLoading(false);
  };

  const onDeleteWorkout = async (workoutId) => {
    await deleteWorkout(workoutId);
    setWorkouts(workouts.filter((w) => w.id !== workoutId));
  };

  return (
    <>
      {client && (
        <>
          <Grid display="flex" justifyContent="space-between" container>
            <Grid item sm={12} md={6} sx={{ mb: 2 }}>
              <Typography>
                <b>{client.name}</b>
              </Typography>
              <Typography>{client.email}</Typography>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="flex-end">
              <Button onClick={() => setOpenAddWorkout(true)} sx={{ mr: 1 }}>
                Add Workout
              </Button>
              <LoadingButton onClick={copyWeek} loading={copyLoading}>
                Copy Week
              </LoadingButton>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {workouts.length === 0 && <NoWorkoutsCard />}
            {workouts.map((workout) => (
              <WorkoutCard
                workout={workout}
                actions={
                  <>
                    <Button
                      size="small"
                      onClick={() => setSelectedWorkout(workout)}
                      sx={{ ml: 2 }}
                    >
                      Log Workout
                    </Button>
                    <Button
                      size="small"
                      onClick={() => setEditingWorkout(workout)}
                      sx={{ ml: 2 }}
                    >
                      Edit Workout
                    </Button>
                    <Button
                      size="small"
                      onClick={() => onDeleteWorkout(workout.id)}
                      sx={{ ml: 2 }}
                    >
                      Delete Workout
                    </Button>
                  </>
                }
              />
            ))}
            <WeekSwitcher
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Box>
          {editingWorkout && (
            <AddWorkoutDetails
              workout={editingWorkout}
              userId={client?.id}
              handleClose={() => setEditingWorkout(null)}
            />
          )}
          {openAddWorkout && (
            <AddWorkoutDetails
              userId={client?.id}
              handleClose={() => setOpenAddWorkout(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ClientWorkouts;
