import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, onAuthStateChanged, signOut as signOutFB } from "firebase/auth";
import { createContext, useState, useContext, useEffect } from "react";
import Loading from "../components/Layout/Loading";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJSSG4PW5SxPDAAXSYXZvsjxtC5jz71gc",
  authDomain: "trainer-app-3ea6d.firebaseapp.com",
  projectId: "trainer-app-3ea6d",
  storageBucket: "trainer-app-3ea6d.appspot.com",
  messagingSenderId: "1087605891451",
  appId: "1:1087605891451:web:7350a12d798db502ba5dde",
  measurementId: "G-2X6K7QKTSQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [ user, setUser ] = useState(auth.currentUser || {});
  const [ error, setError ] = useState("")
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if(!user.id){
      onAuthStateChanged(auth, (u) => {
        if (u) {
          setUser(u);
        }
        setLoading(false);
      });
    }
  }, [user])
  
  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch(error){
      setError(error.message);
    } 
  }

  const signUp = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      return userCredential.user;
    } catch(error){
      setError(error.message);
    }
  }

  const signOut = async () =>  {
    try {
      await signOutFB(auth);
      setUser({})
    } catch(error){
      setError(error.message)
    }
  }

  const sendResetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch(error){
      setError(error.message)
    }
  }

  if(loading){
    return <Loading style={{ mt: 6}} />
  }

  return (
    <AuthContext.Provider value={{ user, setUser, login, signUp, error, signOut, sendResetPassword }}>
        {children}
    </AuthContext.Provider>
)
}


// Initialize Firebase