import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  Avatar,
  TableRow,
  TableCell,
  TableBody,
  CardMedia,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { useApi } from "../../utils/api";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CancelIcon from "@mui/icons-material/CloseOutlined";
import { useMutation, useQuery } from "@apollo/client";
import { addAttachment, getConversation } from "./queries";
import { useUserInfo } from "../../utils/user";
import { sendMessage } from "../Messaging/queries";

const ReviewClientActivity = ({
  reviewingActivity,
  onReviewed,
  activityLoading,
}) => {
  const { saveWorkoutDetails } = useApi();
  const [reviewed, setReviewed] = useState(reviewingActivity?.reviewed);
  const [loading, setLoading] = useState(false);
  const { data } = useQuery(getConversation, {
    variables: { userId: reviewingActivity.user.id },
  });

  useEffect(() => {
    setReviewed(reviewingActivity?.reviewed);
  }, [reviewingActivity]);

  const toggleReviewed = async () => {
    setLoading(true);
    await saveWorkoutDetails(reviewingActivity.id, { reviewed: !reviewed });
    setReviewed(!reviewed);
    setLoading(false);
    onReviewed();
  };
  return (
    <Grid>
      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="space-between"
        alignItems="top"
      >
        <Box>
          <Typography variant="h6">{reviewingActivity.name}</Typography>
          <Typography>{reviewingActivity.user.name}</Typography>
          <Typography>
            {moment.unix(reviewingActivity.date).format("MMMM Do YYYY")}
          </Typography>
        </Box>
        <Box>
          <LoadingButton
            onClick={toggleReviewed}
            variant="contained"
            loading={loading || activityLoading}
            color={reviewed ? "info" : "primary"}
          >
            {reviewed ? "Reviewed" : "Mark Reviewed"}
          </LoadingButton>
        </Box>
      </Box>
      {reviewingActivity.exercises.map((exercise, index) => (
        <ReviewExercise
          exercise={exercise}
          index={index}
          conversationId={
            data?.getConversationUser.conversations.items[0]?.conversation.id
          }
        />
      ))}
    </Grid>
  );
};

export const ReviewExercise = ({ exercise, index, conversationId }) => {
  const [comment, setComment] = useState();
  const [expand, setExpand] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [addMessage] = useMutation(sendMessage);
  const [createAttachment] = useMutation(addAttachment);
  const { name } = useUserInfo();

  const onComment = async () => {
    const { data } = await addMessage({
      variables: {
        input: {
          conversationMessagesId: conversationId,
          author: name,
          body: comment,
        },
      },
    });
    await createAttachment({
      variables: {
        input: {
          messageAttachmentId: data.createMessage.id,
          type: "EXERCISE",
          body: JSON.stringify(exercise),
        },
      },
    });
    setComment("");
  };
  return (
    <Card elevation={2} sx={{ mt: 2, p: 2 }}>
      {index !== undefined && <Typography>Exercise #{index + 1}</Typography>}
      <Typography variant="h6">{exercise.name}</Typography>
      <Box sx={{ mt: 1 }}>
        {selectedVideo && (
          <CardMedia
            id="player"
            key={selectedVideo}
            fullWidth
            height="600"
            component="video"
            src={`https://trainer-app-videos.s3.us-east-2.amazonaws.com/public/${selectedVideo}`}
            controls
            autoPlay={true}
          />
        )}
      </Box>
      <SetsTable sets={exercise.sets} setSelectedVideo={setSelectedVideo} />
      {conversationId && (
        <>
          <TextField
            sx={{ mt: 2 }}
            label="Leave a comment"
            multiline
            fullWidth
            rows={expand ? 4 : 1}
            onFocus={() => setExpand(true)}
            onBlur={() => setExpand(false)}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onComment}>Comment</Button>
          </Box>
        </>
      )}
    </Card>
  );
};

const SetsTable = ({ sets, setSelectedVideo }) => (
  <Table sx={{ mb: 2 }}>
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell align="center">Reps</TableCell>
        <TableCell>Weight</TableCell>
        <TableCell>RPE</TableCell>
        <TableCell>Video</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {sets.map((set, setIndex) => (
        <TableRow>
          <TableCell>
            <Typography>{setIndex + 1}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{set.reps}</Typography>
          </TableCell>
          <TableCell>{set.weight}</TableCell>
          <TableCell>{set.rpe}</TableCell>
          <TableCell>
            {set.video ? (
              <Avatar
                sx={{ width: 40, height: 40 }}
                sx={{ mt: 2, mr: 1 }}
                onClick={() => setSelectedVideo(set.video)}
              >
                <video
                  src={`https://trainer-app-videos.s3.us-east-2.amazonaws.com/public/${set.video}`}
                  width="50"
                />
                <PlayArrowIcon sx={{ position: "absolute" }} />
              </Avatar>
            ) : (
              <CancelIcon sx={{ ml: 1 }} />
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default ReviewClientActivity;
