import React from 'react';
import moment from 'moment';
import { Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody,TableContainer, Card, Button, TextField} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { COACHING_PRO_PRICING, COACHING_STANDARD_PRICING } from '../utils/prices';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../utils/api';
import CheckoutForm from './Payment/CheckoutForm';
import Loading from './Layout/Loading';

const STEPS = {
    LANDING: 'LANDING',
    PAYMENT: 'PAYMENT',
    SUCCESS: 'SUCCESS',
}
const Coaching = () => {
    const [step, setStep] = useState(STEPS.LANDING);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const onSelectProduct = (product) => {
        setSelectedProduct(product);
        setStep(STEPS.PAYMENT);
    }
    const onSuccess = () => {
        setStep(STEPS.SUCCESS)
    }

    return (
        <Grid sx={{ mt: 4}}>
            {step === STEPS.LANDING && ( <Landing selectProduct={onSelectProduct}/> )}
            {step === STEPS.PAYMENT && ( <Payment selectedProduct={selectedProduct} onSuccess={onSuccess}/> )}
            {step === STEPS.SUCCESS && ( <Success selectedProduct={selectedProduct}/> )}
        </Grid>
    )
}

const Landing = ({ selectProduct }) => (
    <Grid container justifyContent="center">
        <Grid  item lg={12} sm={12} sx={{ textAlign: 'center'}}>
            <Typography variant="h4" sx={{mb: 4}}>Trainer App</Typography>
            <Typography variant="h6" sx={{mb: 4}}>Distribute workouts and give feedback to your clients all in one place.</Typography>
        </Grid>
  
       

        <Grid item lg={5} sm={12} sx={{width: '100%'}}>
            <StandardPricingCard selectProduct={selectProduct} />
        </Grid>
        <Grid lg={1} sm={2}></Grid>
        <Grid item lg={5} sm={12} sx={{mt:4}} sx={{width: '100%'}}>
            <ProPricingCard selectProduct={selectProduct} />
        </Grid>
    </Grid>

)

const StandardPricingCard = ({ selectProduct }) => (
    <Card sx={{ p: 2, mb: 3 ,position: 'relative', height: selectProduct ? '220px' : 'auto',  textAlign: 'center'}}>
        <Typography variant="h6">Standard</Typography>
        <Typography  variant="h5">$25 a month</Typography>
        <Typography sx={{mt: 2}}>Up to 20 clients</Typography>
        {selectProduct && <Button fullWidth sx={{ position: 'absolute', bottom: 0, mb: 1, left: '5%', width:'90%' }} onClick={() => selectProduct(COACHING_STANDARD_PRICING)}>Select Standard</Button>}
    </Card>
)

const ProPricingCard = ({ selectProduct }) => (
    <Card sx={{ p: 2, position: 'relative', height: selectProduct ? '220px' : 'auto', textAlign: 'center'}}>
        <Typography  variant="h6">Pro</Typography>
        <Typography variant="h5">$50 a month</Typography>
        <Typography sx={{mt: 2}}>Up to 50 clients</Typography>
        <Typography>Customizable Theme</Typography>
        {selectProduct && <Button disabled fullWidth sx={{ position: 'absolute', bottom: 0, mb: 1, left: '5%', width:'90%' }} onClick={() => selectProduct(COACHING_PRO_PRICING)}>Not Available Yet</Button>}
    </Card>
)

const stripePromise = loadStripe('pk_test_51IHV32GgCJe4NaBq2FAbtJXfakW1NionV3YO9lyzK5pp9qmSY5pb9iTgQYGV36yh17hp7lHGSRoUie3Mn2Nh9RYC00QF2ikxL5');
const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#ff0000',
        fontFamily: 'Roboto Mono, monospace',
      }
  };

const Payment = ({selectedProduct, onSuccess}) => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const { addCoach, getSetupIntent} = useApi();
    const [setupIntent, setSetupIntent] = useState();

    const createCoach = async (body) => {
        return await addCoach({ ...body, name, email, product: selectedProduct  })
    }

    useEffect(() => {
        const send = async () => {
            const resposne = await getSetupIntent();
            setSetupIntent(resposne.client_secret);  
        }
        send();
    }, [])

    if(!setupIntent){
        return <Loading />
    }

    
    return (
        <Grid container justifyContent="center">
            <Grid  item lg={12} sm={12} sx={{ textAlign: 'center'}}>
                <Typography variant="h4" sx={{mb: 4}}>Payment</Typography>
            </Grid>
            <Grid lg={12}>
                {selectedProduct === COACHING_STANDARD_PRICING && ( <StandardPricingCard /> )}
                {selectedProduct === COACHING_PRO_PRICING && ( <ProPricingCard /> )}
                <Card sx={{ textAlign: 'center', p: 2, mt: 3}}>
                    <Typography variant="h6">Details</Typography>
                    <Typography>You'll be charged $50.00 today to start your subscription. You'll be charged $50.00 on the {moment().format('Do')} of every month to continue your subscription. You can cancel at anytime.</Typography>
                </Card>
                <Card sx={{p: 2, mt: 3}}>
                    <Typography variant="h6">User Info</Typography>
                    <TextField  sx={{mt: 2}} fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)}/>
                    <TextField sx={{mt: 2}} fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </Card>
            </Grid>
            <Grid sx={{mt: 3, width: '100%'}} lg={12}>
                <Elements stripe={stripePromise} options={{ clientSecret: setupIntent, appearance, fonts: [{'family': 'Roboto Mono, monospace', 'cssSrc':'https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;500;700'}] }}>
                    <CheckoutForm onSuccess={onSuccess} price={selectedProduct} email={email} createOrUpdateUser={createCoach}/>
                </Elements>
            </Grid>
    </Grid>
    )
}

const Success = ({ selectedProduct }) => (
    <Card sx={{mb: 3, p: 2}}>
        <Typography variant="h6" sx={{mb: 1}}>Thanks for signing up!</Typography>
        <Typography sx={{mb: 2}}>You have signed up for the {selectedProduct === COACHING_STANDARD_PRICING ? 'Standard' : 'Pro'} Coaching Package.</Typography>
        <Typography sx={{mb: 2}}><b>You will be billed {selectedProduct === COACHING_STANDARD_PRICING ? '$25.00' : '$50.00'} automatically on the {moment().format('Do')} of every month.</b> </Typography>
        <Typography sx={{mb: 2}}>A payment to <b>Trainer App</b> will appear on your statement.</Typography>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Vendor</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Trainer App</TableCell>
                        <TableCell>{selectedProduct === COACHING_STANDARD_PRICING ? '$25.00' : '$50.00'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Typography sx={{ color: '#ff0000', mt: 3, textAlign: 'center' }}>Check your email to set up your account</Typography>
    </Card>
)

export default Coaching;