import React, { createContext, useContext } from 'react';
import { useAuth } from './auth';


const API_PATH = 'https://api.trainer-app.com/'
const APIContext = createContext({});

export const useApi = () => useContext(APIContext)

export const APIContextProvider = ({children}) => {
   const { user } = useAuth();
   const { accessToken } = user;
    
    return (
        <APIContext.Provider value={{ 
            accessToken,
            getWorkouts: (startDate, endDate) => fetchApi(accessToken, `workouts/?userId=${user.uid}&startDate=${startDate}&endDate=${endDate}`),
            getWorkout: (workoutId) => fetchApi(accessToken, `workouts/?workoutId=${workoutId}`),
            saveWorkoutDetails: (workoutId, body) => fetchApi(accessToken, `workouts/?workoutId=${workoutId}`,{ method: 'PATCH', body: JSON.stringify(body) }),
            getCurrentUser: () => fetchApi(accessToken, `users/?userId=${user.uid}`),
            getUser: (userId) => fetchApi(accessToken, `users/?userId=${userId}`),
            getClients: () => fetchApi(accessToken, `users/?coachId=${user.uid}`),
            addClient: (body) => fetchApi(accessToken, `users/`, { method: 'POST', body: JSON.stringify({ ...body, coachId: user.uid }) }),
            addCoach: (body) => fetchApi(accessToken, `users/`, { method: 'POST', body: JSON.stringify({ ...body, isCoach: true }) }),
            updateUser: (body) => fetchApi(accessToken, `users/?userId=${user.uid}`, { method: 'PATCH', body: JSON.stringify(body) }),
            addWorkoutForUser: (userId, body) => fetchApi(accessToken,`workouts/?userId=${userId}`, { method: 'POST', body: JSON.stringify(body) }),
            getWorkoutsForUser: (userId, startDate, endDate) => fetchApi(accessToken, `workouts/?userId=${userId}&startDate=${startDate}&endDate=${endDate}`),
            deleteWorkout: (workoutId) => fetchApi(accessToken, `workouts/?workoutId=${workoutId}`, { method: 'DELETE'}),
            createSubscription: (stripeCustomerId, price) => fetchApi(accessToken, 'payments/', { method: 'PATCH', body: JSON.stringify({ stripeCustomerId, price })}),
            getSubscription: (stripeCustomerId) => fetchApi(accessToken, `subscriptions/?stripeCustomerId=${stripeCustomerId}`),
            cancelSubscription: (subscriptionId) => fetchApi(accessToken, 'subscriptions/', {method: 'DELETE', body: JSON.stringify({ subscriptionId })} ),
            uncancelSubscription: (subscriptionId) => fetchApi(accessToken, 'subscriptions/', {method: 'PATCH', body: JSON.stringify({ subscriptionId })} ),
            createCustomer: (body) => fetchApi(accessToken, 'payments/', { method: 'POST', body: JSON.stringify(body)}),
            getSetupIntent: () => fetchApi(accessToken, 'payments/'),
            getPaymentMethod: (stripeCustomerId) => fetchApi(accessToken, `payment-methods/?stripeCustomerId=${stripeCustomerId}`),
            submitClientIntake: (body) => fetchApi(accessToken, 'client-intakes/', { method: 'POST', body: JSON.stringify(body)}),
            getClientIntakes: () => fetchApi(accessToken, `client-intakes/?coachId=${user.uid}`),
            addBatchWorkouts: (body) => fetchApi(accessToken, 'batch-workouts/', { method: 'POST', body: JSON.stringify(body)}),
            getSignedUrl: () => fetchApi(accessToken, 'videos/'),
            getExerciseHistory: (exerciseName, startDate, endDate) => fetchApi(accessToken, `history/?userId=${user.uid}&exerciseName=${exerciseName}&startDate=${startDate}&endDate=${endDate}`),
            getActivity: (reviewed) => fetchApi(accessToken, `activity/?coachId=${user.uid}&reviewed=${reviewed}`),
            checkIfMigrated: (email) => fetchApi(accessToken, `migrate/?email=${email}`),
            migrate: (email, id, at) => fetchApi(at, `migrate/`, { method: 'POST', body: JSON.stringify({ email, id })})
        }}>
            {children}
        </APIContext.Provider>
    )
}

const fetchApi = (token, path = "", opts = {}) => { 
    return fetch(`${API_PATH}${path}`, {
        credentials: 'same-origin',
        headers: {
            'authorization': `Bearer ${token}`
        },
        ...opts
    }).then(r => r.json()).catch(e => console.error(e));
};