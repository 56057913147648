import gql from 'graphql-tag';
export const getMessages = gql`query GetMessages(\$conversationId: ID!, \$nextToken: String) {
    messagesByDate(conversationMessagesId: \$conversationId, limit: 20, sortDirection: DESC, nextToken: \$nextToken) {
      items {
          body
          id
          author
          createdAt
          attachment {
          items {
            id
            body
            type
          }
        }
      }
        nextToken
        startedAt
    }
  }`

export const getMostRecentMessage = gql`query GetMessages(\$conversationId: ID!) {
    messagesByDate(conversationMessagesId: \$conversationId, limit: 1, sortDirection: DESC) {
      items {
          body
          id
          author
          updatedAt
      }
    }
  }`

  export const subscribe = gql`subscription onCreateMessage(\$conversationId: ID!) {
    onCreateMessage(conversationMessagesId: \$conversationId) {
      id
      author
      body
      createdAt
      conversationMessagesId
    }
  }`

  export const sendMessage = gql`mutation CreateMessage(
    \$input: CreateMessageInput!
  ) {
    createMessage(input: \$input) {
      id
      author
      body
      createdAt
      conversationMessagesId
    }
  }`

  export const getConversationUser = gql`query GetConversationUsers($id: ID!) {
    getConversationUser(id: $id) {
      conversations {
        items {
          conversation {
            id
           users {
            items {
                conversationUserID
              conversationUser {
                name
              }
            }
          }
          }
        }
      }
    }
  }
  `