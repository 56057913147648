import React from "react";
import { Form, Field } from "react-final-form";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useApi } from "../../utils/api";
import { useAuth } from "../../utils/auth";
import moment from "moment";
import { useUserInfo } from "../../utils/user";
import {
  createConversation,
  createConversationUser,
  createUserConversations,
} from "../../graphql/mutations";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const AddClient = ({ onClose }) => {
  const { id } = useUserInfo();
  const { addClient } = useApi();
  const { signUp, sendResetPassword } = useAuth();
  const [addConversation] = useMutation(gql(createConversation));
  const [addConversationUser] = useMutation(gql(createConversationUser));
  const [addUserConversations] = useMutation(gql(createUserConversations));

  const onSubmit = async (v) => {
    const firebaseUser = await signUp(v.email, "TrainerApp!@#$%");
    await addClient({
      ...v,
      id: firebaseUser.uid,
      createdDate: moment().unix(),
    });
    await sendResetPassword(v.email);
    // const {
    //   data: { createConversation: conversation },
    // } = await addConversation({
    //   variables: {
    //     input: {},
    //   },
    // });
    // const {
    //   data: { createConversationUser: conversationUser },
    // } = await addConversationUser({
    //   variables: {
    //     input: {
    //       id: firebaseUser.uid,
    //       name: v.name,
    //     },
    //   },
    // });
    // await addUserConversations({
    //   variables: {
    //     input: {
    //       conversationID: conversation.id,
    //       conversationUserID: conversationUser.id,
    //     },
    //   },
    // });
    // await addUserConversations({
    //   variables: {
    //     input: {
    //       conversationID: conversation.id,
    //       conversationUserID: id,
    //     },
    //   },
    // });
    onClose();
  };

  return (
    <Dialog open>
      <DialogTitle>Add Client</DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <>
            <DialogContent>
              <Field
                name="name"
                render={({ input }) => (
                  <TextField
                    label="Name"
                    size="small"
                    sx={{ mb: 2, mt: 1 }}
                    fullWidth
                    {...input}
                  />
                )}
              />
              <Field
                name="email"
                render={({ input }) => (
                  <TextField label="Email" size="small" fullWidth {...input} />
                )}
              />
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button onClick={handleSubmit} variant="contained">
                Add Client
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

export default AddClient;
