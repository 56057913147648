import { Typography, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForward';
const WeekSwitcher = ({ startDate, endDate, setStartDate, setEndDate }) => {
    const goBackInTime = () => {
        setStartDate(startDate.clone().subtract(1, "week").startOf('day'));
        setEndDate(endDate.clone().subtract(1, "week").endOf('day'));
    }

    const goForwardInTime = () => {
        setStartDate(startDate.clone().add(1, "week").startOf('day'));
        setEndDate(endDate.clone().add(1, "week").endOf('day'));
    }

    return (
        <Grid
            sx={{mt: 3}}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <ArrowBackIosIcon onClick={goBackInTime} fontSize='small'/>
            <Typography variant="subtitle2">{`${startDate.format('M.D.YYYY')} - ${endDate.format('M.D.YYYY')}`}</Typography>
            <ArrowForwardIosIcon onClick={goForwardInTime} fontSize='small'/>
        </Grid>
    )
}

export default WeekSwitcher;