import React, {  useState, useEffect } from 'react';
import { Button, Grid, Box} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useApi } from '../../utils/api';
import WorkoutCard from '../Workouts/WorkoutCard';
import WeekSwitcher from '../Workouts/WeekSwitcher';
import NoWorkoutsCard from '../Workouts/NoWorkoutsCard';
import Loading from '../Layout/Loading';

const MyWorkouts = () => {
    const { getWorkouts } = useApi()
    const [workouts, setWorkouts] = useState([]);
    const [startDate, setStartDate] = useState(moment().weekday(0).startOf('day'));
    const [endDate, setEndDate] = useState(moment().weekday(6).endOf('day'));
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const setSelectedWorkout = (w) => {
        navigate(`/workouts/${w.id}`, { state: { prevPath: location.pathname }});
    }
    
    useEffect(() => {
        const get = async () => {
            const workoutsResponse = await getWorkouts(startDate.unix(), endDate.unix());
            setWorkouts(workoutsResponse.sort((w1, w2) => w1.date - w2.date));
            setLoading(false);
        }
        get();
    }, [startDate, endDate]);


    if(loading){
        return (
            <Loading style={{ mt: 4 }}/>
        )
    }

    return (
        <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} md={6} sx={{mt: 2}}>
                {workouts.length === 0 && (
                        <NoWorkoutsCard />
                    )}
                {workouts.map((workout) => (  
                    <WorkoutCard
                            workout={workout}
                            onCardClick={() => setSelectedWorkout(workout)}
                            actions={(
                                <>
                                    <Button size="small" onClick={() => setSelectedWorkout(workout)} sx={{ml: 2}}>Log Workout</Button>
                                </>
                            )}
                        />
                        ))}
                <WeekSwitcher
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            </Grid>
        </Grid>              
    )
}

export default MyWorkouts;