import React from "react";
import ReactDOM from "react-dom";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import { APIContextProvider } from "./utils/api";
import { UserInfoContextProvider } from "./utils/user";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import App from "./App";
import { AuthContextProvider } from "./utils/auth";
import { ApolloProvider } from "@apollo/client";
import client from "./utils/apolloClient";

Amplify.configure(awsExports);

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff0000",
    },
    info: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto Mono", "monospace"].join(","),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <APIContextProvider>
          <UserInfoContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </LocalizationProvider>
          </UserInfoContextProvider>
        </APIContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
