import React, { useState , useEffect, createContext, useContext } from 'react';
import { useApi } from './api';

const UserInfoContext = createContext({
    roles: [],
})

export const useUserInfo = () => useContext(UserInfoContext)

export const UserInfoContextProvider = ({children}) => {
    const [loading, setLoading] = useState(true);
    const { accessToken, getCurrentUser } = useApi();
    const [userProfile, setUserProfile] = useState({});
    
    useEffect(() => {
        const getUserMetadata = async () => {
            const user = await getCurrentUser();
            setUserProfile(user);
            setLoading(false);
        };
        if(accessToken){
            getUserMetadata();
        } else {
            setLoading(false);
        }
    }, [accessToken]);
    if(loading){
        return null;
    }

    return (
        <UserInfoContext.Provider value={{ ...userProfile, loading }}>
            {children}
        </UserInfoContext.Provider>
    )
}