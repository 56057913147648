import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useApi } from "../../utils/api";
import Loading from "../Layout/Loading";
import { useUserInfo } from "../../utils/user";
import {
  createConversation,
  createConversationUser,
  createUserConversations,
} from "../../graphql/mutations";

const StartConversation = ({ onClose }) => {
  const { id } = useUserInfo();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);
  const { getClients } = useApi();

  const [addConversation] = useMutation(gql(createConversation));
  const [addConversationUser] = useMutation(gql(createConversationUser));
  const [addUserConversations] = useMutation(gql(createUserConversations));

  const startConversation = async () => {
    // create a conversation
    const {
      data: { createConversation: conversation },
    } = await addConversation({
      variables: {
        input: {},
      },
    });
    // create a conversation user
    const {
      data: { createConversationUser: conversationUser },
    } = await addConversationUser({
      variables: {
        input: {
          id: client.id,
          name: client.name,
        },
      },
    });

    // create a userConversations connection
    await addUserConversations({
      variables: {
        input: {
          conversationID: conversation.id,
          conversationUserID: conversationUser.id,
        },
      },
    });
    await addUserConversations({
      variables: {
        input: {
          conversationID: conversation.id,
          conversationUserID: id,
        },
      },
    });
    // redirect to /conversations/:conversationid?
    onClose();
  };

  useEffect(() => {
    const get = async () => {
      const clientsResponse = await getClients();
      setClients(clientsResponse);
      setLoading(false);
    };
    get();
  }, []);

  return (
    <Dialog open>
      <DialogTitle>
        <Typography>Start a Conversation</Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <InputLabel>Client</InputLabel>
            <Select
              fullWidth
              value={client}
              onChange={(e) => setClient(e.target.value)}
            >
              {clients.map((client) => (
                <MenuItem value={client}>{client.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={startConversation}>Start Conversation</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartConversation;
