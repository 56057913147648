import React, { useEffect, useState } from "react";
import { Paper, Typography, Button, Grid, Box } from "@mui/material";
import { useApi } from "../../utils/api";
import AddClient from "./AddClient";
import Loading from "../Layout/Loading";

const Clients = ({ setClient, activeClient = {} }) => {
  const { getClients } = useApi();
  const [clients, setClients] = useState([]);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const c = await getClients();
      setClients(c.filter((client) => !client.deleted));
      setLoading(false);
      setClient(c[0]);
    };
    fetch();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid>
      {clients.length ? (
        <>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Clients</Typography>
            <Button onClick={() => setOpenAddClient(true)}>Add Client</Button>
          </Grid>
          {clients.map((client) => (
            <Paper
              sx={{
                mt: 2,
                p: 2,
                backgroundColor:
                  activeClient.id === client.id ? "red" : "white",
                color: activeClient.id === client.id ? "white" : "inherit",
              }}
              key={client.id}
              onClick={() => setClient(client)}
            >
              <Typography
                sx={{ mb: 1 }}
                sx={{ color: activeClient.id === client.id ? "white" : "red" }}
              >
                <b>{client.name}</b>
              </Typography>
              <Typography>{client.email}</Typography>
            </Paper>
          ))}
        </>
      ) : (
        <Paper sx={{ mt: 2, p: 2 }}>
          <Box>
            <Typography sx={{ mb: 2 }} sx={{ color: "#ff0000" }}>
              <b>You have no clients!</b>
            </Typography>
            <Typography sx={{ mt: 2, mb: 3 }}>
              Click below to invite a client to join you on Trainer App.
            </Typography>
            <Button onClick={() => setOpenAddClient(true)} fullWidth>
              Add Client
            </Button>
          </Box>
        </Paper>
      )}
      {openAddClient && <AddClient onClose={() => setOpenAddClient(false)} />}
    </Grid>
  );
};

export default Clients;
