import React from 'react';
import {Card, CardContent, Box, Typography, CardActions, Grid} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import moment from 'moment';

const WorkoutCard = ({ onCardClick = () => {}, workout, actions }) => (
    <Card sx={{ mb: 2}} elevation={2} onClick={onCardClick}>
        <CardContent>
            <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {workout.date && moment.unix(workout.date).format('dddd MMMM Do YYYY')}
                </Typography>
                {workout.completed ? <CheckCircleIcon color="success" /> : <CancelIcon color="primary"/>}
            </Box>
        
            <Typography variant="h6" component="div">
                {workout.name}
            </Typography>
            <Typography variant="body2">
                {workout.description}
            </Typography>
        </CardContent>
        <CardActions> 
            {actions}
        </CardActions>
    </Card>
)

export default WorkoutCard;