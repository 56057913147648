import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import moment from "moment";
import { Navigate } from "react-router-dom";
import { useApi } from "../../utils/api";
import { useUserInfo } from "../../utils/user";

const Subscription = () => {
  const { stripeCustomerId } = useUserInfo();
  const {
    getSubscription,
    cancelSubscription,
    uncancelSubscription,
    getPaymentMethod,
  } = useApi();
  const [subscription, setSubscription] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const send = async () => {
      const sub = await getSubscription(stripeCustomerId);
      const pm = await getPaymentMethod(stripeCustomerId);
      setSubscription(sub);
      setPaymentMethod(pm);
      setLoading(false);
    };
    if (stripeCustomerId) {
      send();
    }
  }, [stripeCustomerId]);

  const handleCancel = async () => {
    setLoading(true);
    const updatedSub = await cancelSubscription(subscription.id);
    setSubscription(updatedSub);
    setLoading(false);
  };

  const handleUncancel = async () => {
    setLoading(true);
    const updatedSub = await uncancelSubscription(subscription.id);
    setSubscription(updatedSub);
    setLoading(false);
  };

  if (!stripeCustomerId || (!loading && !subscription)) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <Grid display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid>
      <Typography variant="h6">Manage Subscription</Typography>
      <Paper sx={{ p: 2 }}>
        <Typography sx={{ mb: 2 }}>
          <b>Subscription</b>
        </Typography>
        <Typography sx={{ mb: 1 }}>Monthly Personal Training</Typography>
        {subscription.cancel_at ? (
          <Alert sx={{ mb: 1 }} severity="error">
            <AlertTitle>Cancelled</AlertTitle>Last billing date is{" "}
            {moment.unix(subscription.cancel_at).format("MMMM Do YYYY")}
          </Alert>
        ) : (
          <>
            <Typography sx={{ mb: 1 }} variant="body2">
              $100 billed on{" "}
              {moment.unix(subscription.billing_cycle_anchor).format("Do")} of
              each month.
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
              Purchased on{" "}
              {moment.unix(subscription.plan.created).format("MMMM Do YYYY")}.
            </Typography>
          </>
        )}
        {subscription.cancel_at ? (
          <LoadingButton fullWidth onClick={handleUncancel} loading={loading}>
            Uncancel Subscription
          </LoadingButton>
        ) : (
          <LoadingButton fullWidth onClick={handleCancel} loading={loading}>
            Cancel Subscription
          </LoadingButton>
        )}
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography sx={{ mb: 2 }}>
          <b>Payment Method</b>
        </Typography>
        <Typography>
          {paymentMethod.card.brand.toUpperCase()} Last 4:{" "}
          {paymentMethod.card.last4} Exp: {paymentMethod.card.exp_month}/
          {paymentMethod.card.exp_year}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Subscription;
