import React, { useState } from 'react';
import { Typography,  Box, TextField, Select, MenuItem, Table, TableRow, TableHead, TableBody, TableCell, Button} from '@mui/material';
import { Field, useForm } from 'react-final-form';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Video from './Video';
import History from './History';

const Exercise = ({ exercise, index, currentWorkoutDate }) => {
    const form = useForm();
    const isComplete = (setIndex) => {
        const key = `exercises[${index}].sets[${setIndex}]`;
        return form.getFieldState(`${key}.rpe`)?.value && form.getFieldState(`${key}.weight`)?.value;
    }
    const [openHistory, setOpenHistory] = useState(false);
    return (
        <Box sx={{p: 2}}>
        <Box display="flex" justifyContent="space-between">
            <Typography variant="h6"><strong>{exercise.name}</strong></Typography>
            <Button onClick={() => setOpenHistory(true)}>History</Button>
        </Box>
        {openHistory && (
            <History exerciseName={exercise.name} close={() => setOpenHistory(false)} currentWorkoutDate={currentWorkoutDate} />
        )}
        <Typography fontSize="small" sx={{'wordBreak': 'break-word'}}>{exercise.description}</Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ padding: '5px'}}>
                         #
                    </TableCell>
                    <TableCell align="center">
                        Reps
                    </TableCell>
                    <TableCell>
                        Weight
                    </TableCell>
                    <TableCell>
                        RPE
                    </TableCell>
                    <TableCell>
                       
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {exercise.sets.map((set, setIndex) => (
                    <TableRow>
                         <Field  name={`exercises[${index}].sets[${setIndex}].type`} 
                            render={({ input }) => (
                                <input type='hidden' value={input.value} />
                            )}
                        />
                        <TableCell sx={{ padding: '5px'}}>
                            <Typography>
                                {setIndex + 1}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography>
                                {set.reps}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Field
                                name={`exercises[${index}].sets[${setIndex}].weight`}
                                render={({input })=> (
                                    <TextField
                                        name={input.name} onChange={input.onChange} value={input.value} 
                                        variant="outlined" 
                                        size='small' 
                                        sx={{ width: 77, align: 'right'}}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            <Field
                                name={`exercises[${index}].sets[${setIndex}].rpe`}
                                render={({ input }) => (
                                    <Select 
                                        size='small' 
                                        onChange={input.onChange} 
                                        value={input.value} 
                                        sx={{ width: 67, 'boarder-color': 'green'}}>
                                            <MenuItem value={6}>
                                                6
                                            </MenuItem>
                                            <MenuItem value={7}>
                                                7
                                            </MenuItem>
                                            <MenuItem value={8}>
                                                8
                                            </MenuItem>
                                            <MenuItem value={9}>
                                                9
                                            </MenuItem>
                                            <MenuItem value={10}>
                                                10
                                            </MenuItem>
                                    </Select>
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            {isComplete(setIndex) ? <CheckIcon sx={{color:'green'}} fontSize='small'/> : <CloseIcon sx={{color:'red'}}  fontSize='small'/>}
                        </TableCell>
                    </TableRow>
                ))} 
            </TableBody>
        </Table>
       {/* <Video exerciseIndex={index} /> */}
      </Box>
    )
}

export default Exercise;