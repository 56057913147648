import React from 'react';
import { Navigate } from 'react-router-dom';

const LoginCallback = () => {
    const navigatePath = localStorage.getItem('loginRedirect') || '/';
    return (
        <Navigate to={navigatePath} />
    )
}

export default LoginCallback;