import React, { useState , createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../components/Layout/Loading';
import { useApi } from './api';
import { useUserInfo } from './user';

const CoachContext = createContext({
    roles: [],
})

export const useCoach = () => useContext(CoachContext)

export const CoachContextProvider = ({children}) => {
    const { getUser } = useApi();
    const { isCoach, loading: userInfoLoading } = useUserInfo();
    const isCoaching = isCoach && window.location.pathname.indexOf('coach') > -1;
    const [client, setClient] = useState(null);
    const [reviewingActivity, setReviewingActivity] = useState(null);
    const params = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const get = async () => {
            const user = await getUser(params.userId);
            setClient(user);
            setLoading(false);
        }
        if(params.userId && !client && isCoaching && !userInfoLoading){
            get()
        } else {
            setLoading(false);
        }
    }, [params.userId, client?.id, isCoaching, userInfoLoading]);

    if(loading){
        <Loading style={{m: 4 }}/>
    }

    return (
        <CoachContext.Provider value={{ isCoaching, client, setClient, loading, reviewingActivity, setReviewingActivity }}>
            {children}
        </CoachContext.Provider>
    )
}