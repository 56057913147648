import React, { useEffect, useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import {
  Dialog,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogContent,
  Box,
  Grid,
  CircularProgress,
  AppBar,
  Toolbar,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useApi } from "../../utils/api";

const History = ({ exerciseName, currentWorkoutDate, close }) => {
  const { getExerciseHistory } = useApi();
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dates, setDates] = useState([
    moment().subtract(1, "months"),
    moment.now(),
  ]);

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const result = await getExerciseHistory(
        exerciseName,
        moment(dates[0]).unix(),
        moment(dates[1]).unix()
      );
      setExercises(
        result
          .filter((e) => e.date !== currentWorkoutDate)
          .sort((e1, e2) => e2.date - e1.date)
      );
      setLoading(false);
    };
    get();
  }, [dates]);

  return (
    <Dialog open fullScreen>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ ml: 2, flex: 1 }}>
            <Typography variant="h6">{exerciseName} History</Typography>
            <Typography variant="caption">
              {moment(dates[0]).format("M.D.YYYY")} -{" "}
              {moment(dates[1]).format("M.D.YYYY")}
            </Typography>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpenDatePicker(true)}
            aria-label="calendar"
          >
            <CalendarIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent display="flex" justifyContent="center">
        <DatePicker
          defaultValue={dates}
          open={openDatePicker}
          close={() => setOpenDatePicker(false)}
          updateDates={setDates}
        />
        {loading ? (
          <Grid display="flex" sx={{ m: 2 }} justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : exercises.length === 0 ? (
          <Typography sx={{ mt: 2 }}>
            There's no history for this exercise and date range.
          </Typography>
        ) : (
          <>
            {exercises.map((exercise) => (
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: 14, textAlign: "center" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {moment.unix(exercise.date).format("dddd MMMM Do YYYY")}
                </Typography>
                <Table sx={{ mb: 4 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: "5px" }}>#</TableCell>
                      <TableCell align="center">Reps</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>RPE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exercise.sets.map((set, setIndex) => (
                      <TableRow>
                        <TableCell sx={{ padding: "5px" }}>
                          <Typography>{setIndex + 1}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{set.reps}</Typography>
                        </TableCell>
                        <TableCell>{set.weight}</TableCell>
                        <TableCell>{set.rpe}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const DatePicker = ({ updateDates, open, close, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <Dialog open={open} fullWidth>
      <DialogContent>
        <MobileDateRangePicker
          startText="Start"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 1 }}> to </Box>
              <TextField {...endProps} />
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            updateDates(value);
            close();
          }}
        >
          Save
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default History;
