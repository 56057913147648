import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { createConversationUser } from "../../graphql/mutations";
import { useUserInfo } from "../../utils/user";
import { Card, Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import StartConversation from "./StartConversation";
import ConversationCard from "./ConversationCard";
import { useNavigate } from "react-router-dom";
import { getConversationUser } from "./queries";

const Conversations = ({ onClick, selectedConverationId }) => {
  const { name, id } = useUserInfo();
  const [startConversation, setStartConversation] = useState(false);
  const navigate = useNavigate();
  const {
    loading,
    data: { getConversationUser: conversationUser } = {},
    refetch,
  } = useQuery(getConversationUser, {
    variables: {
      id,
    },
  });
  const [addConversationUser] = useMutation(gql(createConversationUser), {
    variables: {
      input: {
        id,
        name,
      },
    },
  });

  useEffect(() => {
    const createAndRefetch = async () => {
      await addConversationUser();
      await refetch();
    };
    if (!loading && !conversationUser) {
      createAndRefetch();
    }
  }, [loading, conversationUser]);

  if (conversationUser?.conversations?.items.length === 1) {
    navigate(
      `/messages/${conversationUser?.conversations?.items[0].conversationID}`
    );
  }

  return (
    <Grid>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h6">Conversations</Typography>
        <Button onClick={() => setStartConversation(true)}>
          Start Conversation
        </Button>
      </Box>
      {conversationUser?.conversations?.items.length ? (
        <>
          {conversationUser.conversations.items.map(({ conversation }) => (
            <ConversationCard
              conversation={conversation}
              onClick={onClick}
              selected={selectedConverationId === conversation.id}
            />
          ))}
        </>
      ) : (
        <Card sx={{ p: 2 }}>
          <Typography>No conversations, start a new one!</Typography>
        </Card>
      )}
      {startConversation && (
        <StartConversation onClose={() => setStartConversation(false)} />
      )}
    </Grid>
  );
};

export default Conversations;
