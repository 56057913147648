import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
     <Grid sx={{ textAlign: 'center'}}>
        <Typography variant="h4" sx={{mt:4}}>Trainer App</Typography>
        <Typography sx={{mt:4, mb: 4, 'line-height': '34px'}}>A remote fitness coaching platform focused on strength building fitness instruction. Built for powerlifting, weightlifting and general strength training coaching and instruction. </Typography>
        <Button component={Link} to="/coaching" sx={{mt:2}} fullWidth>Learn more about pricing</Button>
    </Grid>
    )
}

export default Home;