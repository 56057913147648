import { Dialog, AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Box } from '@mui/system';

const ViewClientIntake = ({ clientIntake, handleClose }) => {
    return (
        <Dialog fullScreen open>
             <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6">
                    Client Intake
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box sx={{p:3}}>
                <Typography variant="caption">Name</Typography>
                <Typography sx={{mb: 2}}>{clientIntake.fullname}</Typography>
                <Typography variant="caption">Email</Typography>
                <Typography sx={{mb: 2}}>{clientIntake.email}</Typography>
                <Typography variant="caption">Age</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.age}</Typography>
                <Typography variant="caption">Goals</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.goals}</Typography>
                <Typography variant="caption">Current Exercise</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.currentExercise}</Typography>
                <Typography variant="caption">Current 1rm</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.current1rm}</Typography>
                <Typography variant="caption">Medical History</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.medical}</Typography>
                <Typography variant="caption">Injury History</Typography>
                <Typography  sx={{mb: 2}}>{clientIntake.injuries}</Typography>
                <Typography variant="caption">Extra Information</Typography>
                <Typography>{clientIntake.extra}</Typography>
              </Box>
        </Dialog>
    )
}

export default ViewClientIntake;