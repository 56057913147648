import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { CircularProgress, Grid, Paper, Table, TableContainer, TableHead, TableRow, TableCell, Typography, TableBody, Button } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { useApi } from '../../utils/api';
import { useUserInfo } from '../../utils/user';
import CheckoutForm from './CheckoutForm';
import { CLIENT_STANDARD_PRICING, CLIENT_EDUCATION_PRICING } from '../../utils/prices';

const stripePromise = loadStripe('pk_live_51IHV32GgCJe4NaBq5Al3nmOpiBJiThpTMv4kGPyeyQuvXyHNbD1frOsptoUivtGoThymvcNd932rrLEZceBrZYs4006E8pZtRc');
const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#ff0000',
        fontFamily: 'Roboto Mono, monospace',
      }
  };

const Payment = () => {
    const { isEducationDiscount, email } = useUserInfo();
    const [setupIntent, setSetupIntent] = useState();
    const [success, setSuccess] = useState(false);
    const { getSetupIntent, updateUser } = useApi();
    const { stripeCustomerId } = useUserInfo();
    const price = isEducationDiscount ? CLIENT_EDUCATION_PRICING : CLIENT_STANDARD_PRICING;
    useEffect(() => {
        const send = async () => {
            const resposne = await getSetupIntent();
            setSetupIntent(resposne.client_secret);  
        }
        if(!stripeCustomerId){
            send();
        }
    }, [])

    if(stripeCustomerId){
        return (
            <Navigate to="/subscription" />
        )
    };

    if(!setupIntent){
        return <Grid
                display="flex"
                justifyContent="center"
                sx={{mt: 2}}
            > 
            <CircularProgress />
        </Grid>
    }
    return (
        <Elements stripe={stripePromise} options={{ clientSecret: setupIntent, appearance, fonts: [{'family': 'Roboto Mono, monospace', 'cssSrc':'https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;500;700'}] }}>
            <Typography variant="h6" sx={{mb: 2}}>Monthly Personal Training</Typography>
            <Grid container>
                {success ? (
                     <Paper sx={{mb: 3, p: 2}}>
                        <Typography variant="h6" sx={{mb: 1}}>Thanks for signing up!</Typography>
                        <Typography sx={{mb: 2}}>You have signed up for Monthly Personal Training from Mike Abell CPT.</Typography>
                        {isEducationDiscount ? (
                            <>
                                 <Typography ><b>You will be billed $50 (Education discount) automatically on the {moment().format('Do')} of every month.</b> </Typography>
                                 <Typography sx={{mb: 2}}>You will be required to provide proof of active enrollment or employment in the education field every quarter.</Typography>
                            </>
                           
                        ): (
                            <Typography sx={{mb: 2}}><b>You will be billed $100 automatically on the {moment().format('Do')} of every month.</b> </Typography>
                        )}
                        <Typography sx={{mb: 2}}>A payment to <b>Mike Abell CPT</b> will appear on your statement.</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Vendor</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Mike Abell CPT</TableCell>
                                        <TableCell>{isEducationDiscount ? '$50.00' : '$100.00'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button sx={{ mt: 3, width: '100%'}} component={Link} to="/workouts">Go to client portal</Button>
                    </Paper>
                ) : (
                    <>
                        <Grid lg={5} >
                            <Paper sx={{mb: 3, p: 2}}>
                                <Typography variant="h6" sx={{mb: 1}}>Cost</Typography>
                               
                                <Typography sx={{mb: 1}}><strong>{isEducationDiscount ? '$50.00 (Education discount)' : '$100.00'}</strong> monthly</Typography>
                                <Typography variant='caption' >Charged today and on the {moment().format('Do')} of every following month. Cancel anytime.</Typography>
                            </Paper>
                        </Grid>
                        <Grid lg={1}></Grid>
                        <Grid lg={6} sx={{width: '100%'}}>
                            <CheckoutForm onSuccess={() => setSuccess(true)} price={price} email={email} createOrUpdateUser={updateUser}/>
                        </Grid>
                    </>
                )}
               
            </Grid>
            
        </Elements>
    )
};

export default Payment;