import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/auth";

const ProtectedRoute = () => {
    const { user } = useAuth();

    return (
        user.accessToken ? <Outlet /> : <Navigate to="/login" />
    );
}

export default ProtectedRoute;