import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
  Grid,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import { Field } from "react-final-form";
import ClearIcon from "@mui/icons-material/Clear";
import { FieldArray } from "react-final-form-arrays";
import History from "../ClientPortal/History";

const AddExercise = ({ index, removeExercise }) => {
  const [openHistory, setOpenHistory] = useState(false);
  return (
    <Box sx={{ mt: 3 }}>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Exercise {index + 1}</Typography>
        <ClearIcon color={"warning"} onClick={removeExercise} />
      </Grid>
      <Field
        subscription={{ value: true }}
        name={`exercises[${index}].name`}
        render={({ input }) => (
          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              label="Exercise name"
              value={input.value}
              onChange={input.onChange}
            />
            <Button onClick={() => setOpenHistory(true)}>History</Button>
            {openHistory && (
              <History
                exerciseName={input.value}
                close={() => setOpenHistory(false)}
              />
            )}
          </Box>
        )}
      />
      <Field
        subscription={{ value: true }}
        name={`exercises[${index}].description`}
        render={({ input }) => (
          <TextField
            fullWidth
            multiline
            size="small"
            sx={{ mt: 2 }}
            label="Exercise description"
            value={input.value}
            onChange={input.onChange}
          />
        )}
      />
      <FieldArray
        subscription={{ value: true }}
        name={`exercises[${index}].sets`}
        render={({ fields }) => (
          <>
            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "5px" }}>#</TableCell>
                  <TableCell align="center">Reps</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>RPE</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((name, setIndex) => (
                  <TableRow key={name}>
                    <Field
                      name={`${name}.type`}
                      render={({ input }) => (
                        <input type="hidden" value={input.value} />
                      )}
                    />
                    <TableCell sx={{ padding: "5px" }}>
                      {setIndex + 1}
                    </TableCell>
                    <TableCell align="center">
                      <Field
                        subscription={{ value: true }}
                        name={`${name}.reps`}
                        render={({ input }) => (
                          <TextField
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            variant="outlined"
                            size="small"
                            sx={{ width: 60 }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        subscription={{ value: true }}
                        name={`${name}.weight`}
                        render={({ input }) => (
                          <TextField
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            variant="outlined"
                            size="small"
                            sx={{ width: 60 }}
                            disabled={fields.value[setIndex].type === "RPE"}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        subscription={{ value: true }}
                        name={`${name}.rpe`}
                        render={({ input }) => (
                          <Select
                            size="small"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            disabled={fields.value[setIndex].type === "WEIGHT"}
                            sx={{ width: 67 }}
                          >
                            <MenuItem>Unset</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                          </Select>
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      <ClearIcon
                        color={"warning"}
                        onClick={() => fields.remove(setIndex)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <SetActions fields={fields} />
          </>
        )}
      />
    </Box>
  );
};

const SetActions = ({ fields }) => {
  const [setType, setSetType] = useState(null);
  return (
    <Grid
      sx={{ mt: 1, mb: 1 }}
      fullWidth
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <FormControl sx={{ width: "150px" }}>
        <InputLabel id="demo-simple-select-label">Add Set</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Add Set"
          value={setType}
          onChange={(e) => {
            if (e.target.value !== "RESET") {
              fields.push({ type: e.target.value });
              setSetType("RESET");
            }
          }}
        >
          <MenuItem value={"RESET"}>Add Set</MenuItem>
          <MenuItem value={"WEIGHT"}>WEIGHT</MenuItem>
          <MenuItem value={"RPE"}>RPE</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          if (fields.value && fields.value.length) {
            fields.push({ ...fields.value[fields.length - 1] });
          } else {
            fields.push({});
          }
        }}
      >
        Copy Set
      </Button>
    </Grid>
  );
};

export default AddExercise;
