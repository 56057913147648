import { Grid, Typography } from "@mui/material";
import React from "react";
import me from "../images/mikey.jpg";
const About = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {" "}
        <b>About</b>
      </Typography>
      <Grid container>
        <Grid item lg={5} sx={{ mb: 2 }}>
          <img src={me} width="100%" />
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={6}>
          <Typography sx={{ mb: 2 }}>
            Hi! I'm Mike. I've been an athlete all my life. Starting with
            swimming when I was kid, to rock climbing and now powerlifting.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            I've always trained people in my respective sports as well. I really
            enjoy sharing my passions with others while teaching them the best
            techniques.
          </Typography>
          <Typography>
            I've recently passed my NASM certification to become a Certified
            Personal Trainer. I'm excited to take on new clients and share what
            I know with you.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
